import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, signal, WritableSignal } from '@angular/core';
import { NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';

import { MediaResolver } from '@services/resolvers/auxiliary/media-resolver';

import { LegacyMediaDataUrls, MediaDataUrls, MediaMetas } from '@interfaces/media';
import { DEFAULT_RESPONSIVE_IMAGE_CONFIG } from '@core/config';

export interface IResponsiveImageConfig {
  src: string;
  dataUrls?: LegacyMediaDataUrls | MediaDataUrls;
  srcSet: string;
  sizes: string;
  metas?: Partial<MediaMetas>;
  placeholder: boolean;
  placeholderImage?: string;
  priority: boolean;
  loading?: 'eager' | 'lazy' | 'auto';
  fill: boolean;
  loaderParams?: Record<string, string>;
  cssClass?: string;
}

@Component({
  selector: 'sps-image',
  standalone: true,
  imports: [NgTemplateOutlet, NgOptimizedImage, NgClass],
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() set config(config: Partial<IResponsiveImageConfig>) {
    if (config?.dataUrls) {
      this.$config.update(current => {
        const next = { ...current, ...config };

        const { srcSet, src, loaderParams } = this.mediaResolver.buildImageSourceSet(config.dataUrls);
   
        next.loaderParams ||= loaderParams;
        next.srcSet = srcSet;
        next.src = src;

        return next;
      });
    }
    this.cd.markForCheck();
  }
  public $config: WritableSignal<IResponsiveImageConfig> = signal({ ...DEFAULT_RESPONSIVE_IMAGE_CONFIG });

  constructor(private mediaResolver: MediaResolver, private el: ElementRef, private cd: ChangeDetectorRef) {}

  get width(): number | null {
    if (this.$config().fill) {
      return null;
    }

    return this.$config().metas?.width ?? null;
  }

  get height(): number | null {
    if (this.$config().fill) {
      return null;
    }

    return this.$config().metas?.height ?? null;
  }

  get alt(): string {
    return this.$config().metas?.alt || 'Image';
  }

  public setLoaded(ev: Event) {
    this.el.nativeElement.classList.add('loaded');
    this.cd.markForCheck();
  }
}
